import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const dialogContentStyles = cva(
	[
		'flex fixed z-50 h-dvh max-h-dvh md:max-h-[90dvh] w-dvw flex-col overflow-hidden rounded-none md:h-auto md:rounded-md top-0 left-0 md:top-auto md:left-auto',
	],
	{
		variants: {
			positioned: {true: 'md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2'},
			size: {sm: 'md:max-w-md', md: 'md:max-w-lg', lg: 'md:max-w-xl', xl: 'md:max-w-2xl'},
			animated: {
				true: 'duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 md:data-[state=closed]:zoom-out-95 md:data-[state=open]:zoom-in-95 md:data-[state=closed]:slide-out-to-left-1/2 md:data-[state=closed]:slide-out-to-top-[48%] md:data-[state=open]:slide-in-from-left-1/2 md:data-[state=open]:slide-in-from-top-[48%]',
				false: '',
			},
		},
		defaultVariants: {positioned: true, size: 'md', animated: true},
	},
);

export const dialogOverlayStyles = cva(
	[
		'fixed left-0 top-0 z-20 h-full w-dvw pointer-events-none',
		'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
	],
	{
		variants: {blur: {true: 'pointer-events-none bg-mauve-a1 backdrop-blur-md'}},
		defaultVariants: {blur: true},
	},
);

export type DialogContentVariantProps = VariantProps<typeof dialogContentStyles>;
export type DialogOverlayVariantProps = VariantProps<typeof dialogOverlayStyles>;
