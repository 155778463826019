import {forwardRef} from 'react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import Dialog from '../dialog';
import {sheetStyles} from './styles';
import type {
	DialogContentProps,
	DialogDescriptionProps,
	DialogFooterProps,
	DialogProps,
	DialogTitleProps,
	DialogTriggerProps,
} from '../dialog';
import type {SheetVariantProps} from './styles';

export type SheetProps = DialogProps;
export type SheetTitleProps = DialogTitleProps;
export type SheetDescriptionProps = DialogDescriptionProps;
export type SheetTriggerProps = DialogTriggerProps;
export type SheetFooterProps = DialogFooterProps;

export type SheetContentProps = DialogContentProps & SheetVariantProps & {blur?: boolean};
const SheetContent = forwardRef<HTMLDivElement, SheetContentProps>(
	({blur = false, side = 'left', children, className, ...props}, forwardedRef) => (
		<Dialog.Content
			{...props}
			animated={false}
			blur={blur}
			className={twMerge(sheetStyles({side}), className)}
			positioned={false}
			ref={forwardedRef}
		>
			{children}
		</Dialog.Content>
	),
);

const Sheet = assign((props: SheetProps) => <Dialog {...props} />, {
	Content: SheetContent,
	Description: Dialog.Description,
	Footer: Dialog.Footer,
	Title: Dialog.Title,
	Trigger: Dialog.Trigger,
});

Sheet.Content.displayName = 'Sheet.Content';
Sheet.Description.displayName = 'Sheet.Description';
Sheet.Footer.displayName = 'Sheet.Footer';
Sheet.Title.displayName = 'Sheet.Title';
Sheet.Trigger.displayName = 'Sheet.Trigger';

export default Sheet;

export * from './styles';
