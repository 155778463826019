'use client';

import {useEffect, useState} from 'react';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {Logo} from '@job-ish/blocks/components';
import {Anchor, IconButton, Separator, Sheet} from '@job-ish/ui/components';
import {IconArrowRight, IconMenu2} from '@tabler/icons-react';

import {HeaderNavigationMenuList} from './list';

export const HeaderNavigationMenu = () => {
	const [sheetOpen, setSheetOpen] = useState(false);
	const {user} = useSupabase();

	useEffect(() => {
		if (sheetOpen) window.location.hash = '';
	}, [sheetOpen]);

	const handleHashChange = () => {
		if (!window.location.hash) return;
		setSheetOpen(false);
		setTimeout(() => {
			document.querySelector(window.location.hash)?.scrollIntoView({behavior: 'smooth'});
		}, 50);
	};

	useEffect(() => {
		window.addEventListener('hashchange', handleHashChange);
		return () => window.removeEventListener('hashchange', handleHashChange);
	}, [sheetOpen]);

	return (
		<div className="h-16 w-full">
			<nav className="absolute left-1/2 hidden w-fit -translate-x-1/2 items-center justify-evenly gap-8 py-8 text-sm md:flex">
				<HeaderNavigationMenuList />
			</nav>
			<div className="absolute right-1 top-3 flex md:hidden">
				<Sheet onOpenChange={setSheetOpen} open={sheetOpen}>
					<Sheet.Trigger asChild>
						<IconButton aria-label="Open navigation menu" icon={IconMenu2} intent="ghost" />
					</Sheet.Trigger>
					<Sheet.Content
						className="[&_button]:right-2.5 [&_button]:top-3 [&_button]:h-10 [&_button]:w-10 [&_svg]:h-5 [&_svg]:w-5"
						padding="none"
					>
						<Sheet.Title className="px-3.5">
							<Logo />
						</Sheet.Title>
						<Sheet.Description className="flex h-full flex-col items-start justify-start gap-6 px-6 text-lg text-mauve12">
							<HeaderNavigationMenuList />
							<Separator className="w-full" />
							<Anchor
								className="font-semibold text-mauve11 visited:text-mauve11"
								href="https://app.job-ish.com/auth/login"
								suffix={<IconArrowRight className="h-4 w-4" />}
							>
								{user ? 'Back to Board' : 'Log In'}
							</Anchor>
						</Sheet.Description>
					</Sheet.Content>
				</Sheet>
			</div>
		</div>
	);
};
