import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const sheetStyles = cva(
	[
		'md:rounded-none w-dvw sm:max-w-none [&&]:top-0 border-mauve6 h-full flex flex-col md:h-full md:max-w-4xl max-h-dvh [&&]:max-h-dvh',
		'fixed transition ease-in-out data-[state=open]:fade-in-0 data-[state=close]:fade-out-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=open]:duration-200',
	],
	{
		variants: {
			side: {
				left: 'inset-y-0 [&&]:left-0 border-r sm:data-[state=closed]:slide-out-to-left sm:data-[state=open]:slide-in-from-left',
				right:
					'inset-y-0 [&&]:right-0 border-l sm:data-[state=closed]:slide-out-to-right sm:data-[state=open]:slide-in-from-right',
			},
		},
		defaultVariants: {side: 'left'},
	},
);

export type SheetVariantProps = VariantProps<typeof sheetStyles>;
