import {forwardRef, useEffect, useState} from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';
import CloseButton from '../close-button';
import Paper from '../paper';
import {dialogContentStyles, dialogOverlayStyles} from './styles';
import type {PaperVariantProps} from '../paper/styles';
import type {DialogContentVariantProps, DialogOverlayVariantProps} from './styles';

export type DialogTriggerProps = DialogPrimitive.DialogTriggerProps;
const DialogTrigger = DialogPrimitive.Trigger;

export type DialogContentProps = Omit<DialogPrimitive.DialogContentProps, 'asChild'> & {
	closeTrigger?: React.ReactNode;
	positioned?: boolean;
	dismissable?: boolean;
} & PaperVariantProps &
	DialogContentVariantProps &
	DialogOverlayVariantProps;
const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
	(
		{
			blur,
			positioned,
			closeTrigger,
			dismissable = true,
			padding = 'md',
			size,
			animated,
			bordered = true,
			shadow = true,
			children,
			className,
			...props
		},
		forwardedRef,
	) => (
		<DialogPrimitive.Portal>
			<DialogPrimitive.Overlay asChild ref={forwardedRef}>
				<div className={dialogOverlayStyles({blur})} />
			</DialogPrimitive.Overlay>
			<DialogPrimitive.Content {...props} asChild ref={forwardedRef}>
				<Paper
					bordered={bordered}
					className={twMerge(dialogContentStyles({positioned, size, animated}), className)}
					enableMotion={false}
					padding={padding}
					shadow={shadow}
				>
					{dismissable && closeTrigger !== null && (
						<DialogPrimitive.Close asChild className="absolute right-2.5 top-2.5">
							{closeTrigger || <CloseButton size="sm" />}
						</DialogPrimitive.Close>
					)}
					{children}
				</Paper>
			</DialogPrimitive.Content>
		</DialogPrimitive.Portal>
	),
);

export type DialogTitleProps = DialogPrimitive.DialogTitleProps;
const DialogTitle = forwardRef<HTMLHeadingElement, DialogTitleProps>(
	({className, ...props}, forwardedRef) => (
		<DialogPrimitive.Title
			{...props}
			className={twMerge('mb-4 text-lg font-semibold text-mauve12', className)}
			ref={forwardedRef}
		/>
	),
);

export type DialogDescriptionProps = DialogPrimitive.DialogDescriptionProps;
const DialogDescription = forwardRef<HTMLParagraphElement, DialogDescriptionProps>(
	({className, ...props}, forwardedRef) => (
		<DialogPrimitive.Description
			{...props}
			className={twMerge('mb-4 text-pretty text-mauve11', className)}
			ref={forwardedRef}
		/>
	),
);

export type DialogFooterProps = ComponentPropsWithoutRef<'div'>;
const DialogFooter = forwardRef<HTMLDivElement, DialogFooterProps>(({className, ...props}, forwardedRef) => (
	<div
		{...props}
		className={twMerge('mt-auto flex grow items-end justify-end gap-3 pt-4', className)}
		ref={forwardedRef}
	/>
));

export type DialogProps = DialogPrimitive.DialogProps;
const Dialog = assign(
	({defaultOpen, open, ...props}: DialogProps) => {
		const [deferredDefaultOpen, setDeferredDefaultOpen] = useState(false);

		useEffect(() => {
			if (defaultOpen && typeof window !== 'undefined') {
				setDeferredDefaultOpen(true);
			}
		}, [defaultOpen]);

		return (
			<DialogPrimitive.Root
				{...props}
				defaultOpen={deferredDefaultOpen}
				open={open === undefined && defaultOpen ? deferredDefaultOpen : open}
			/>
		);
	},
	{
		Trigger: DialogTrigger,
		Content: DialogContent,
		Title: DialogTitle,
		Description: DialogDescription,
		Footer: DialogFooter,
	},
);

DialogTrigger.displayName = 'Dialog.Trigger';
DialogContent.displayName = 'Dialog.Content';
DialogTitle.displayName = 'Dialog.Title';
DialogDescription.displayName = 'Dialog.Description';

export default Dialog;
