import {Anchor} from '@job-ish/ui/components';
import NextLink from 'next/link';

export const HeaderNavigationMenuList = () => (
	<>
		<NextLink
			className="focus-visible:outline-none"
			href="/#features"
			passHref
			scroll={false}
			tabIndex={-1}
			title="Features"
		>
			<Anchor as="div" className="font-semibold text-mauve11">
				Features
			</Anchor>
		</NextLink>
		<NextLink
			className="focus-visible:outline-none"
			href="/#pricing"
			passHref
			scroll={false}
			tabIndex={-1}
			title="Pricing"
		>
			<Anchor as="div" className="font-semibold text-mauve11">
				Pricing
			</Anchor>
		</NextLink>
		<NextLink
			className="focus-visible:outline-none"
			href="/#faq"
			passHref
			scroll={false}
			tabIndex={-1}
			title="Frequently Asked Questions"
		>
			<Anchor as="div" className="font-semibold text-mauve11">
				FAQs
			</Anchor>
		</NextLink>
		<Anchor
			className="font-semibold text-mauve11 visited:text-mauve11"
			href="https://app.job-ish.com"
			title="Job Board"
		>
			Job Board
		</Anchor>
	</>
);
