'use client';

import {Anchor, IconButton, Separator, TextButton} from '@job-ish/ui/components';
import {IconBrandInstagram, IconBrandTiktok, IconBrandX} from '@tabler/icons-react';
import dayjs from 'dayjs';

export const JOB_BOARD_LINKS = {
	'Jobs by Location': [
		{
			href: 'https://app.job-ish.com/job-board?countryCode=US',
			label: 'Top Jobs in the United States',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=CA',
			label: 'Top Jobs in Canada',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=GB',
			label: 'Top Jobs in the United Kingdom',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=AU',
			label: 'Top Jobs in Australia',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=DE',
			label: 'Top Jobs in Germany',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=FR',
			label: 'Top Jobs in France',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=ES',
			label: 'Top Jobs in Spain',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=SG',
			label: 'Top Jobs in Singapore',
		},
		{
			href: 'https://app.job-ish.com/job-board?countryCode=IN',
			label: 'Top Jobs in India',
		},
	],
	'Jobs by Type': [
		{
			href: 'https://app.job-ish.com/job-board?typeId=1',
			label: 'Top Full Time Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?typeId=1&hasRemote=true',
			label: 'Top Remote Full Time Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?typeId=2',
			label: 'Top Part Time Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?typeId=2&hasRemote=true',
			label: 'Top Remote Part Time Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?typeId=3',
			label: 'Top Internships',
		},
		{
			href: 'https://app.job-ish.com/job-board?typeId=4&hasRemote=true',
			label: 'Top Remote Internships',
		},
		{
			href: 'https://app.job-ish.com/job-board?typeId=5',
			label: 'Top Freelance Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?typeId=5&hasRemote=true',
			label: 'Top Remote Freelance Jobs',
		},
	],
	'Jobs by Industry': [
		{
			href: 'https://app.job-ish.com/job-board?companyIndustryId=81',
			label: 'Top Security Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?companyIndustryId=20',
			label: 'Top IT Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?companyIndustryId=154',
			label: 'Top Aviation Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?companyIndustryId=31',
			label: 'Top Construction Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?companyIndustryId=103',
			label: 'Top Fashion Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?companyIndustryId=25',
			label: 'Top Healthcare Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Acustomer+service%5C%22%22%5D',
			label: 'Top Customer Service Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Acrypto%5C%22%22%5D',
			label: 'Top Web3 & Crypto Jobs',
		},
	],
	'Jobs with Benefits': [
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Adental+insurance%5C%22%22%5D',
			label: 'Top Jobs with Dental Insurance',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Ahealth+insurance%5C%22%22%5D',
			label: 'Top Jobs with Health Insurance',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Avision+insurance%5C%22%22%5D',
			label: 'Top Jobs with Vision Insurance',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Alife+insurance%5C%22%22%5D',
			label: 'Top Jobs with Life Insurance',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Afree+lunch%5C%22%22%5D',
			label: 'Top Jobs with Free Lunch',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3A401k%5C%22%22%5D',
			label: 'Top Jobs with 401k',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Aunlimited+pto%5C%22%22%5D',
			label: 'Top Jobs with Unlimited PTO',
		},
		{
			href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22keyword%3Aflexible+hours%5C%22%22%5D',
			label: 'Top Jobs with Flexible Hours',
		},
	],
	'Jobs by Experience Level': [
		{
			href: 'https://app.job-ish.com/job-board?experienceLevel=EN',
			label: 'Top Entry Level Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?experienceLevel=MI',
			label: 'Top Mid Level Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?experienceLevel=SE',
			label: 'Top Senior Level Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?experienceLevel=EX',
			label: 'Top Executive Jobs',
		},
	],
	'Jobs by Work Preference': [
		{
			href: 'https://app.job-ish.com/job-board?hasRemote=false',
			label: 'Top On-Site Jobs',
		},
		{
			href: 'https://app.job-ish.com/job-board?hasRemote=true',
			label: 'Top Remote Jobs',
		},
	],
};

export type FooterProps = {
	cta?: React.ReactNode;
};

const Footer = ({cta}: FooterProps) => (
	<>
		<footer className="flex flex-col items-center gap-4 py-8">
			<div className="flex w-full flex-col items-center justify-between gap-12 py-4">
				{cta}
				<div className="flex flex-col items-center gap-2 px-4">
					<div className="flex items-center gap-1.5 text-mauve11">
						<IconButton
							aria-label="Instagram"
							as="a"
							circular
							href="https://www.instagram.com/getjobish"
							icon={IconBrandInstagram}
							intent="ghost"
							rel="noopener noreferrer"
							target="_blank"
						/>
						<IconButton
							aria-label="TikTok"
							as="a"
							circular
							href="https://www.tiktok.com/@job.ish"
							icon={IconBrandTiktok}
							intent="ghost"
							rel="noopener noreferrer"
							target="_blank"
						/>
						<IconButton
							aria-label="X"
							as="a"
							circular
							href="https://x.com/job_ish"
							icon={IconBrandX}
							intent="ghost"
							rel="noopener noreferrer"
							target="_blank"
						/>
					</div>
					<span className="text-xs text-mauve11">© {dayjs().year()} job-ish. All rights reserved.</span>
				</div>
				<div className="flex items-center gap-3 px-4 text-xs">
					<TextButton
						as="a"
						className="font-semibold text-mauve11"
						href="https://job-ish.com/privacy-policy"
						size="xs"
						target="_blank"
						title="Privacy"
					>
						Privacy
					</TextButton>
					<Separator className="h-3" orientation="vertical" />
					<TextButton
						as="a"
						className="font-semibold text-mauve11"
						href="https://job-ish.com/terms"
						size="xs"
						target="_blank"
						title="Terms"
					>
						Terms
					</TextButton>
				</div>
			</div>
			<Separator className="my-8 w-full" />
			<div className="grid w-fit grid-cols-1 gap-16 px-6 pb-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
				{Object.entries(JOB_BOARD_LINKS).map(([category, links]) => (
					<div className="w-full" key={category}>
						<h3 className="mb-2 text-sm font-semibold text-mauve12">{category}</h3>
						<div className="flex flex-col gap-2">
							{links.map(({href, label}) => (
								<Anchor
									className="text-sm text-mauve11 visited:text-mauve11 sm:text-xs"
									href={href}
									key={href}
									target="_blank"
								>
									{label}
								</Anchor>
							))}
						</div>
					</div>
				))}
			</div>
		</footer>
	</>
);

export default Footer;
